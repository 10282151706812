import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import AboutUsNav from "./components/AboutUsNav";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Preloader from "./components/Preloader";

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading by using useEffect
  useEffect(() => {
    // Simulate an API call or resource loading
    const timer = setTimeout(() => {
      setLoading(false); // Hide preloader after resources load
    }, 3000); // Adjust time to simulate load

    return () => clearTimeout(timer); // Cleanup timeout if component unmounts
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Preloader /> // Show preloader if still loading
      ) : (
        <div className="main-content">
          {/* Your main content goes here */}
          <Navbar></Navbar>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/about" element={<AboutUsNav />}></Route>
            <Route path="/services" element={<Services />} />
          </Routes>
          <Footer></Footer>
        </div>
      )}
    </div>
  );
}

export default App;
